export default {
    colors: {
        black : '#1d1d1f',
        white : '#fff',
        village: '#1b1f1b',
        hero: '#cdc8bc',
        footer: '#322b29',
        event: '#232226',
        blog: '#1c2527',
        grandAtelier: '#212527',
        boisFlotte: '#2d2f28',
        decoration: '#2e2b1e',
        partenaire: '#202421',
        contact: '#28291e',
        cabane: '#362921',
        sculpture: '#342813',
    },
    fonts: {
        heading: 'EDO SZ',
        body: 'Avenir Roman'
    },
    fontSizes: {
        xs: "0.75rem",
        sm: "0.875rem",
        md: "1rem",
        lg: "1.25rem",
        xl: "1.5rem",
        "2xl": "1.875rem",
        "heroSubtitle" :"1.1rem",
        "heroTitle": "2.7rem",
        "4xl": "4rem",
        "5xl": "5rem",
        "6xl": "6rem",
        "7xl": "7rem",

      },
    breakpoints:{
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',  
        '2xl': '1408px'
    },
    heights: {
        footer: '183px',
        navbar: '4.75rem',
        closedFoldableGallery: '14rem',
        maxCardImageHeight: '173px'
    }
}