import React, {useState} from 'react'
import theme from '../../theme/theme'
import {ThemeProvider} from 'styled-components'
import GlobalStyle from '../../theme/globalStyles'
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from "gatsby"
import {lighten} from 'polished'
import {document} from 'browser-monads'
import {up} from 'styled-breakpoints'
import {
    NavItem
  } from '../navbar/navbarWrapper'

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    > div {
        flex: 1;
    }
    nav{
        transition: all .3s ease-out;
        .items{
            display:none;
        }
        .container{
            max-width: 800px;
        }
    } 
    nav.open{
        .d-flex{
            display:flex;   
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100vw;
            height: 100vh;    
        }
        .items {
            display: flex;
            flex-direction: column;
            align-items: center;
            >div{
                padding: 5px 0;
            }
        }
        
        position: fixed;
        right: 0;
        top: 0;
        background:  ${p => lighten(0.2,p.theme.colors.black)};
        transition: all .3s ease-in-out;
        z-index: 99;
        font-size: 1.7rem;
        width: 100vw;

        a{
            color: ${p => p.theme.colors.white};
            text-align:center;
            ${up("md")}{
                font-size: ${p => p.theme.fontSizes.lg};
            }
        }
        .close-cross{
            position: relative;
            top: 10px;
            right: 23%;
            margin-left: auto;
            width: 50px;
            height: 50px;
            transition: transform .3s ease-in-out;

            &:hover{
                transform: scale(1.1);
            }
            span{
                position: absolute;
                top: 0;
                width: 50px;
                height: 5px;
                background: white;
                &:first-child{
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    transform: rotate(-45deg);
                }
            }
        }
    }
`
const DefaultLayout = ({children}) => {

    
    function closeMenu(){
        document.getElementById("nav").classList.remove("open")
    }
    
    const {bois, cabane, deco, sculpture, site} = useStaticQuery(graphql`
    query MobileNavQuery {
      
        site {
            siteMetadata {
            menuLinks {
                name
                link 
            }
            }
        }
        bois: strapiCreations(type: {eq: "Boisflotte"}) {
            slug
            type
        }
        cabane: strapiCreations(type: {eq: "Cabane"}) {
            slug
            type
        }
        deco: strapiCreations(type: {eq: "Decoration"}) {
            slug
            type
        }
        sculpture: strapiCreations(type: {eq: "Sculpture"}) {
            slug
            type
        }
    }
    `)

    return(
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <StyledLayout>
                {children}
                <nav id="nav">
                    <div className="container d-flex">
                        <div className="close-cross" onClick={() => closeMenu()}>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="items">
                            <NavItem><Link to="/">Acceuil</Link></NavItem>
                            <NavItem>
                                <Link to={`/village`}>Village des Branchés</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={`/ateliers`}>Ateliers</Link>
                            </NavItem>
                            <NavItem>
                                <Link key={bois.slug} to={`/${bois.type}/${bois.slug}`}>Bois flotté</Link>
                            </NavItem>
                            <NavItem>
                                <Link key={cabane.slug} to={`/${cabane.type}/${cabane.slug}`}>Cabane</Link>
                            </NavItem>
                            <NavItem>
                                <Link key={deco.slug} to={`/${deco.type}/${deco.slug}`}>Décoration</Link>
                            </NavItem>
                            <NavItem>
                                <Link key={sculpture.slug} to={`/${sculpture.type}/${sculpture.slug}`}>Sculpture</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={`/partenaires`}>Partenaires</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={`/a-propos`}>A propos</Link>
                            </NavItem>
                            <NavItem>
                                <Link to={`/a-propos#contact`}>Contact</Link>
                            </NavItem>
                    
                        </div>
                    </div>
                </nav>
            </StyledLayout>
         
        </ThemeProvider>
    )
}
export default DefaultLayout