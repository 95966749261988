import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, article, event}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
      }

      return (
        <>
          <Helmet htmlAttributes={{lang: 'fr'}} title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description} />
            {/* {article &&
            <script type="application/ld+json">
            {
              "@context": "https://schema.org",
              "@type": "NewsArticle",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://google.com/article"
              },
              "headline": "Article headline",
              "image": [
                "https://example.com/photos/1x1/photo.jpg",
                "https://example.com/photos/4x3/photo.jpg",
                "https://example.com/photos/16x9/photo.jpg"
               ],
              "datePublished": "2015-02-05T08:00:00+08:00",
              "dateModified": "2015-02-05T09:20:00+08:00",
              "author": {
                "@type": "Person",
                "name": "John Doe"
              },
               "publisher": {
                "@type": "Organization",
                "name": "Google",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://google.com/logo.jpg"
                }
              },
              "description": "A most wonderful article"
            }
            </script>
            } */}
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
      }
    }
  }
`