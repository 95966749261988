import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Link } from 'gatsby'
import {lighten, darken} from 'polished'

const NavItem = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.5;
    padding: .5rem .75rem;
    position: relative;
`

const NavDropdownItem = styled.div`
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.5;
    padding: .5rem .75rem;
    position: relative;
`

const NavDropdown = styled.div`
    padding: .5rem .75rem;
    flex-grow: 0;
    flex-shrink: 0;
    line-height: 1.5;
    position: relative;
    display: ${p => p.isOpen ? 'block' : 'none'};
`

const NavbarDropdownLink = styled.span`
    padding-right: 2.5em;
    position: relative;
    display: block;
    &::after{
      content: " ";
      position: absolute;
      top: 50%;
      transform-origin: center;
      transform: rotate(-45deg);
      pointer-events: none;
      width: .625em;
      height: .625em;
      display: block;
      border: 3px solid ${p => darken(1,p.theme.colors.hero)};
      border-top: 0;
      border-right: 0;
      border-radius: 2px;
      margin-top: -.375em;
      right: 1.125em;
    }
`

const NavbarMenu = styled.div.attrs({
  id: 'navbar'
})`
  display:none;
  padding: .5rem 0;
  z-index: 1;
  position: relative;
  &.is-active{
    display:block;
  }
`

const NavbarWrapper = styled.nav.attrs({
  role: 'navigation',
  ariaLabel: 'main navigation'
})`
  background-color:  ${p => p.theme.colors.hero};
  min-height: ${p => p.theme.heights.navbar};
  ${up('md')}{
    display: none;
  }
  a{
      display: block;
      text-decoration: none; 
      font-size: ${p => p.theme.fontSizes.md};
  }
  a,span{
    color: ${p => darken(1,p.theme.colors.hero)};
  }
`
const NavbarHeader = styled.div`
  display: flex;
  align-items: center;
  [class$="-link"]{
    margin: 0 10px;
  }
  img{
    transform: scale(.9);
  }
  .home-link{
    font-size: ${p => p.theme.fontSizes["2xl"]};
    font-variant: small-caps;
    position: relative;
    left: 5px;
    top: 13px;
  }
  .navbar-logo{
    margin-left: auto;
  }
`

const Burger = styled.div.attrs({
  ariaLabel: 'menu',
  ariaExpanded: false,
  dataTarget: 'navbar'
})`
  background-color:  ${p => lighten(0.05,p.theme.colors.hero)};
  color: ${p => p.theme.colors.white};
  cursor: pointer;
  display: block;
  height: 4.75rem;
  position: relative;
  width: 4.75rem;
  margin-left: auto;
`
const BurgerSlice = styled.span.attrs({
  ariaHidden: true
})`
  display: block;
  background-color: currentColor;
  height: 2px;
  left: calc(50% - 8px);
  position: absolute;
  transform-origin: center;
  transition-duration: 86ms;
  transition-timing-function: ease-out;
  transition-property: background-color,opacity,transform;
  width: 16px;
  &:first-child {
    top: calc(50% - 6px);
  }
  &:nth-child(2) {
    top: calc(50% - 1px);
  }
  &:nth-child(3) {
    top: calc(50% + 4px);
  }
`

export {
  Burger, 
  BurgerSlice, 
  NavbarHeader, 
  NavbarWrapper, 
  NavbarMenu, 
  NavItem, 
  NavDropdownItem, 
  NavDropdown, 
  NavbarDropdownLink
}