import {createGlobalStyle } from 'styled-components'
import {down} from 'styled-breakpoints'

export default createGlobalStyle`
    :root{
        font-size: 18px;
        font-family: 'Avenir Roman';
        ${down('lg')}{
            font-size: 90%;
        }
        ${down('md')}{
            font-size: 85%;
        }
    }
    body{
        margin: 0;
    }
    .z-index-1{
        position: relative;
        z-index: 1;
    }
    .is-flex{
        display: flex;
    }
    .text-center{
        text-align: center;
    }
    ${down('lg')}{
        .hide-on-large-down{
            display: none;
        }
    }
    h1,h2,h3,h4,h5{
        font-family: ${p=> p.theme.fonts.heading};
        font-weight: normal;
    }
    ::-webkit-scrollbar-track
    {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
        border-radius: 10px;
    }

    ::-webkit-scrollbar
    {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: ${p => p.theme.colors.black};
    }
`